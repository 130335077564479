import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { PerfilInput } from '@models/perfil.model';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BaseService } from '@services/base.service';

@Injectable({
  providedIn: 'root',
})
export class PerfilService extends BaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  crearPerfil(perfil: PerfilInput, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation createMbperfil($createMbperfilInput:CreateMbperfilInput!){
  createMbperfil(createMbperfilInput:$createMbperfilInput){
    success
    message
  }
}`;

    const variables = {
      createMbperfilInput: {
        ...perfil,
      },
    };
    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }

  leerPerfiles(id: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbperfilPorCliente($idCliente:ID!){
      mbperfilPorCliente(idCliente:$idCliente){
        _id
        nombre
        tema
        imagen
        listaInfoIdioma{
          profesion

        }
        esActivo

      }
    }`;

    const variables = {
      idCliente: id,
    };
    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map(({ data }: any) => {
        return data?.mbperfilPorCliente;
      }),
      catchError(({ error }) => {
        return throwError(() => error?.message);
      }),
    );
  }

  updatePerfilActivo(id: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    // FIXME: El mbPerfil viene null
    const query = `mutation updateMbperfil($updateMbperfilInput:UpdateMbperfilInput!){
      updateMbperfil(updateMbperfilInput:$updateMbperfilInput){
        success
        message
        mbPerfil {
          _id
          altura
          calificacion
          ciudad
          codigoPostal
          correo
          direccion
          edad
          esPublico
          estadoPais
          experiencia
          fechaNacimiento
          imagen
          latitud
          listaDocumento {
            descripcion
            listaArchivo
            titulo
          }
          listaEnlace
          listaExperienciaLaboral {
            actividad
            cargo
            empresa
            esActual
            fechaDesde
            fechaHasta
          }
          listaInfoIdioma {
            idioma
            profesion
            descripcion
            listaEtiqueta
            mbIndustria{
              _id
              catalogo
            }
          }
          longitud
          nombre
          pais
          peso
          telefono
          tema
          unidadMedidaAltura
          unidadMedidaPeso
          usuario
          verCorreo
          verDireccion
          verAltura
          verCiudad
          verDescripcion
          verEdad
          verPais
          verPeso
          verFoto
          verTelefono
          verWhatsapp
          whatsapp
          zonaHoraria
        }
      }
    }`;

    const variables = {
      updateMbperfilInput: {
        id,
        esActivo: true,
      },
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  updatePerfil(perfil: PerfilInput, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    //console.log('en servicio', perfil);
    const query = `mutation updateMbperfil($updateMbperfilInput:UpdateMbperfilInput!){
  updateMbperfil(updateMbperfilInput:$updateMbperfilInput){
    success
    message


  }
}`;

    const variables = {
      updateMbperfilInput: {
        ...perfil,
      },
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  leerPerfilActivo(id: string, token: string): Observable<any> {
    const headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbclientePorPerfilActivo($id:ID!) {
      mbclientePorPerfilActivo(id:$id) {
        success
        mbCliente {
          _id
          apellidos
          idAccountStripe
          llaveFactorAutenticacion
          nombres
          planSeleccionado
          tipoSuscripcion
          fechaHoraSuscripcion
          codigoReferido
          total
          mbRepresentante{
            tipoMetodoAuthenticate
            usuarioAccesoAuthenticate
            esValidoAuthenticate
            respuestaAuthenticate
            intentoProcesoAuthenticate
            inicioProcesoAuthenticate
            _id
            apellidos
            codigo
            correo
            estadoPais
            fechaNacimiento
            idGoogle
            identificacion
            imagen
            mbTipoCuenta {
              _id
            }
            nombres
            pais
          }
          mbTipoCuenta{
            _id
            catalogo
          }
          mbTransaccionSuscripcion{
            _id
            transaccion
          }
          listaMbPerfil {
            _id
            esPublico
            nombre
            pais
            ciudad
            estadoPais
            latitud
            longitud
            correo
            codigoPostal
            calificacion
            telefono
            fechaNacimiento
            listaEnlace
            direccion
            tema
            imagen
            usuario
            whatsapp
            edad
            peso
            unidadMedidaPeso
            unidadMedidaAltura
            verCorreo
            verDireccion
            verAltura
            verCiudad
            verDescripcion
            verEdad
            verPais
            verPeso
            verFoto
            verTelefono
            verWhatsapp
            altura
            zonaHoraria
            experiencia
            listaDocumento {
              descripcion
              listaArchivo
              titulo
            }
            listaInfoIdioma {
              idioma
              profesion
              descripcion
              listaEtiqueta
              mbIndustria{
                _id
                catalogo
              }
            }
            listaExperienciaLaboral {
              actividad
              cargo
              empresa
              esActual
              fechaDesde
              fechaHasta
            }
          }
          tipoMetodoAuthenticate
          usuarioAccesoAuthenticate
          esValidoAuthenticate
          respuestaAuthenticate
          intentoProcesoAuthenticate
        }
      }
    }`;

    const variables = {
      id,
    };
    return this.http.post(this.apiUrl, { query, variables }, { headers }).pipe(
      map((resp: any) => {
        return resp;
      }),
      catchError(({ error }) => {
        return throwError(() => error?.message);
      }),
    );
  }

  leerTemaActivo(id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbclientePorPerfilActivo($id:ID!){
  mbclientePorPerfilActivo(id:$id){
    success
mbCliente{
 _id
 listaMbPerfil{
  tema
	}
}

  }

}`;

    const variables = {
      id: id,
    };
    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }

  existeUsuario(usuario: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    const query = `query mbperfilExistePorUsuario($usuario:String!){
  mbperfilExistePorUsuario(usuario:$usuario){
    success

  }
}`;

    const variables = {
      usuario: usuario,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  eliminarPerfil(id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation removeMbperfil($id:ID!){
      removeMbperfil(id:$id){
        success
        message
      }
    }`;

    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
  leerPerfil(id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbperfil($id:ID!){
  mbperfil(id:$id){
    message
    success
    mbPerfil{
     _id
    nombre
    tema
    imagen
    usuario
    listaEnlace
    direccion
    calificacion
    estadoPais
    latitud
    longitud
    telefono
    whatsapp
    edad
    peso
    altura
    ciudad
    pais
    correo
    codigoPostal
    verCorreo
    verDireccion
    verAltura
    verCiudad
    verDescripcion
    verEdad
    verPais
    verPeso
    verTelefono
    verWhatsapp
    listaDocumento{
    descripcion
    listaArchivo
    titulo

      }
    listaInfoIdioma{
      profesion
      descripcion
      idioma
      listaEtiqueta
      mbIndustria{
        _id
        catalogo
      }
    }
    listaExperienciaLaboral{
    actividad
    cargo
    empresa
    esActual
    fechaDesde
    fechaHasta

    }
    listaMbContacto
    listaMbContactoSolicitud
    esActivo
    esConfirmarAutomaticamente
    esPagoRequerido
    mbCliente{
      mbTipoCuenta{
        _id
        catalogo
      }
    }
    }
  }
}`;

    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, { query, variables }, { headers });
  }
}
