import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import {
  authLogout,
  businessRegisterError,
  businessRegisterLoad,
  businessRegisterSuccess,
  individualRegisterError,
  individualRegisterLoad,
  individualRegisterSuccess,
  setClientSubscriptionError,
  setClientSubscriptionSuccess,
  updateToken,
} from './auth.actions';
import { catchError, EMPTY, exhaustMap } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { map, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '@core/store.state';
import { setLoggedClient, setSelectedPlan } from '@client-module/store/client.actions';
import { setAppInitializationLoad } from '../../settings/store/settings.actions';
import { LocalStorageService } from '@services/local-storage.service';
import { Router } from '@angular/router';
import { Mbresult } from '@models/auth.model';
import { AlertsService } from '@services/alerts.service';
import { SuscripcionService } from '@services/suscripcion.service';
import { ClienteService } from '@services/cliente.service';
import { TranslocoService } from '@ngneat/transloco';
import { selectIsGoogleAccount } from '@client-module/store/client.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService,
    private router: Router,
    private alertServ: AlertsService,
    private suscServ: SuscripcionService,
    private clientService: ClienteService,
    private translocoService: TranslocoService,
    private subscriptionService: SuscripcionService,
  ) {}

  updateToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateToken),
      concatLatestFrom(() => [this.store.select(selectIsGoogleAccount)]),
      exhaustMap(([{ auth }, isGoogleAccount]) => {
        return this.authService.renovarToken(auth.token).pipe(
          map(({ mbCliente }) => {
            // DOCS: Si la cuenta no ha sido activada navega a /send-code
            this.store.dispatch(
              setLoggedClient({ client: mbCliente, accountIsActive: !mbCliente.codigo }),
            );
            if (!isGoogleAccount && mbCliente.codigo) {
              this.router.navigate(['register/send-code']);
            }
            this.authService.loggedIn = true;

            /** DOCS: Setear subscripción */
            if (mbCliente && mbCliente.tipoSuscripcion) {
              const plan = mbCliente.tipoSuscripcion;
              if (plan.includes('pro')) {
                this.subscriptionService.planSet = 'PRO';
              } else if (plan.includes('premium')) {
                this.subscriptionService.planSet = 'PREMIUM';
              }
            } else {
              this.subscriptionService.planSet = 'BASIC';
            }

            return setAppInitializationLoad();
          }),
          catchError((error) => {
            this.store.dispatch(authLogout());
            return EMPTY;
          }),
        );
      }),
    ),
  );

  individualRegisterLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(individualRegisterLoad),
      exhaustMap(({nombre, email, password, referralCode, partner }) => {
        return this.authService.registerUsers(nombre, email, password, referralCode, partner).pipe(
          map(({ data }) => {
            const mbResult: Mbresult = data.createMbcliente;
            if (mbResult.success) {
              const client = mbResult.mbCliente;
              this.store.dispatch(setLoggedClient({ client, accountIsActive: !client.codigo }));
              this.localStorageService.setItem({
                key: 'auth',
                value: {
                  token: client.token,
                },
              });
              this.router.navigate(['/register/send-code']);
              return individualRegisterSuccess({ client });
            } else {
              this.alertServ.sendMsj('danger', mbResult.message);
              return individualRegisterError();
            }
          }),
        );
      }),
      catchError((error) => {
        this.store.dispatch(individualRegisterError());
        this.alertServ.sendMsj('danger', error.message);
        return EMPTY;
      }),
    );
  });

  businessRegisterLoad$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(businessRegisterLoad),
      exhaustMap(({ createMbclienteempresaInput }) => {
        const messages = this.translocoService.translateObject('business.generalDataForm.messages');
        return this.clientService
          .createMbClienteEmpresa(
            createMbclienteempresaInput,
            this.translocoService.getActiveLang(),
          )
          .pipe(
            map((resp: Mbresult) => {
              if (resp.success) {
                this.alertServ.sendMsj('info', resp.message);

                // DOCS: Nueva implementaciòn ngrx
                const client = resp.mbCliente;
                //console.log('cliente ', client);
                this.store.dispatch(setLoggedClient({ client, accountIsActive: false }));
                this.localStorageService.setItem({
                  key: 'auth',
                  value: {
                    token: client.token,
                  },
                });
                this.router.navigate(['/register/send-code']);
                return businessRegisterSuccess({ client });
              } else {
                this.alertServ.sendMsj('danger', resp.message);
                return businessRegisterError();
              }
            }),
          );
      }),
      catchError((error) => {
        const messages = this.translocoService.translateObject('business.generalDataForm.messages');
        this.store.dispatch(businessRegisterError());
        this.alertServ.sendMsj('danger', messages.error);
        return EMPTY;
      }),
    );
  });

  setClientSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(individualRegisterSuccess, businessRegisterSuccess),
      exhaustMap(({ client }) => {
        this.authService.loggedIn = true;
        return this.suscServ.actualizarMbcliente(client._id, true, client.token).pipe(
          map(({ errors, data }) => {
            if (errors) {
              this.alertServ.sendMsj('danger', errors.message);
            }

            const mbResult: Mbresult = data.updateMbcliente;
            if (mbResult.success) {
              this.store.dispatch(
                setSelectedPlan({ selectedPlan: mbResult.mbCliente.planSeleccionado }),
              );
              return setClientSubscriptionSuccess();
            }
            return setClientSubscriptionError();
          }),
        );
      }),
      catchError((error) => {
        this.store.dispatch(setClientSubscriptionError());
        return EMPTY;
      }),
    ),
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogout),
        tap(() => {
          this.localStorageService.logOut();
          this.authService.loggedIn = false;
          this.router.navigate(['/login']);
        }),
      ),
    { dispatch: false },
  );
}
