<ng-container [ngSwitch]="icon">
  <!-- Home -->
  <svg *ngSwitchCase="'home'" width="25" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 3.79765L21 10.1506V21.1765H18.2V12.7059H9.8V21.1765H7V10.1506L14 3.79765ZM14 0L0 12.7059H4.2V24H12.6V15.5294H15.4V24H23.8V12.7059H28L14 0Z"
      [attr.fill]="fill" />
  </svg>

  <!-- Search -->

  <svg *ngSwitchCase="'search'" width="20" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.438 14.4654H15.3991L15.0309 14.1103C16.3196 12.6112 17.0955 10.665 17.0955 8.54774C17.0955 3.82676 13.2687 0 8.54774 0C3.82676 0 0 3.82676 0 8.54774C0 13.2687 3.82676 17.0955 8.54774 17.0955C10.665 17.0955 12.6112 16.3196 14.1103 15.0309L14.4654 15.3991V16.438L21.0406 23L23 21.0406L16.438 14.4654ZM8.54774 14.4654C5.2733 14.4654 2.63007 11.8222 2.63007 8.54774C2.63007 5.2733 5.2733 2.63007 8.54774 2.63007C11.8222 2.63007 14.4654 5.2733 14.4654 8.54774C14.4654 11.8222 11.8222 14.4654 8.54774 14.4654Z"
      [attr.fill]="fill" />
  </svg>

<!-- Calendar -->
<svg *ngSwitchCase="'calendar'" width="19" height="23" viewBox="0 0 21 23" fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M18.6667 2.3H17.5V0H15.1667V2.3H5.83333V0H3.5V2.3H2.33333C1.05 2.3 0 3.335 0 4.6V20.7C0 21.965 1.05 23 2.33333 23H18.6667C19.95 23 21 21.965 21 20.7V4.6C21 3.335 19.95 2.3 18.6667 2.3ZM18.6667 20.7H2.33333V9.2H18.6667V20.7ZM2.33333 6.9V4.6H18.6667V6.9H2.33333Z"
    [attr.fill]="fill" />
  <circle cx="5.5" cy="12.5" r="1.5" [attr.fill]="fill" />
  <circle cx="5.5" cy="17.5" r="1.5" [attr.fill]="fill" />
  <circle cx="10.5" cy="12.5" r="1.5" [attr.fill]="fill" />
  <circle cx="10.5" cy="17.5" r="1.5" [attr.fill]="fill" />
  <circle cx="15.5" cy="12.5" r="1.5" [attr.fill]="fill" />
</svg>
  <!-- <svg
    *ngSwitchCase="'home'"
    class="h-8 w-8 m-auto p-1"
    width="24"
    height="26"
    viewBox="0 0 24 26"
   [attr.fill]="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.9091 9.76712L11.9416 1L1.09088 9.76712V25H22.9091V9.76712Z"
      [attr.stroke]="stroke"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M11.9416 25V17.9863"
      [attr.stroke]="stroke"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> -->

  <!-- Contacts -->
  <svg *ngSwitchCase="'contacts'" width="28" height="21" viewBox="0 0 30 21" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 13.125C6.99 13.125 0 14.88 0 18.375V21H21V18.375C21 14.88 14.01 13.125 10.5 13.125ZM3.51 18C4.77 17.13 7.815 16.125 10.5 16.125C13.185 16.125 16.23 17.13 17.49 18H3.51ZM10.5 10.5C13.395 10.5 15.75 8.145 15.75 5.25C15.75 2.355 13.395 0 10.5 0C7.605 0 5.25 2.355 5.25 5.25C5.25 8.145 7.605 10.5 10.5 10.5ZM10.5 3C11.745 3 12.75 4.005 12.75 5.25C12.75 6.495 11.745 7.5 10.5 7.5C9.255 7.5 8.25 6.495 8.25 5.25C8.25 4.005 9.255 3 10.5 3ZM21.06 13.215C22.8 14.475 24 16.155 24 18.375V21H30V18.375C30 15.345 24.75 13.62 21.06 13.215ZM19.5 10.5C22.395 10.5 24.75 8.145 24.75 5.25C24.75 2.355 22.395 0 19.5 0C18.69 0 17.94 0.195 17.25 0.525C18.195 1.86 18.75 3.495 18.75 5.25C18.75 7.005 18.195 8.64 17.25 9.975C17.94 10.305 18.69 10.5 19.5 10.5Z"
      [attr.fill]="fill" />
  </svg>

    <!-- Stats -->

    <svg *ngSwitchCase="'stats'" width="18" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 6H20V21H16V6ZM0 15.375H4V21H0V15.375ZM8 10.6875H12V21H8V10.6875Z" [attr.fill]="fill" />
      <path d="M14 0L16.29 2.29L11.41 7.17L7.41 3.17L0 10.59L1.41 12L7.41 6L11.41 10L17.71 3.71L20 6V0H14Z"
        [attr.fill]="fill" />
    </svg>


    <!-- Requests -->
    <svg *ngSwitchCase="'requests'" width="21" height="23" viewBox="0 0 23 23" fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 23C12.7688 23 13.8069 21.9385 13.8069 20.641H9.19308C9.19308 21.9385 10.2312 23 11.5 23ZM18.4208 15.9231V10.0256C18.4208 6.40462 16.5406 3.37333 13.2302 2.57128V1.76923C13.2302 0.790256 12.4574 0 11.5 0C10.5426 0 9.76981 0.790256 9.76981 1.76923V2.57128C6.47091 3.37333 4.57924 6.39282 4.57924 10.0256V15.9231L2.27232 18.2821V19.4615H20.7277V18.2821L18.4208 15.9231ZM16.1138 17.1026H6.88616V10.0256C6.88616 7.10051 8.62788 4.71795 11.5 4.71795C14.3721 4.71795 16.1138 7.10051 16.1138 10.0256V17.1026ZM6.40171 1.86359L4.75226 0.176923C1.98395 2.33538 0.161484 5.66154 0 9.4359H2.30692C2.47994 6.31026 4.04865 3.57385 6.40171 1.86359ZM20.6931 9.4359H23C22.827 5.66154 21.0045 2.33538 18.2477 0.176923L16.6098 1.86359C18.9398 3.57385 20.5201 6.31026 20.6931 9.4359Z"
        [attr.fill]="fill" />
    </svg>
    <div *ngIf="requestsCount" class="notify">

      <p>{{requestsCount}}</p>

    </div>
  <!-- <svg
    *ngSwitchCase="'stats'"
    class="h-8 w-8 m-auto p-1"
    width="28"
    height="28"
    viewBox="0 0 28 28"
     [attr.fill]="fill"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.39026"
      y="10.3658"
      width="8"
      height="16.6341"
      [attr.stroke]="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="18.6097"
      y="8.02438"
      width="8"
      height="18.9756"
      [attr.stroke]="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="10"
      y="1"
      width="8"
      height="26"
      [attr.stroke]="stroke"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> -->


  <!-- <svg
    *ngSwitchCase="'search'"
    class="h-8 w-8 m-auto p-1"
    width="25"
    height="26"
    viewBox="0 0 25 26"
     fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.0124"
      cy="9.39802"
      r="8.39802"
      [attr.stroke]="strokeTwo"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M22.9372 24L15.9366 16.1024"
      [attr.stroke]="strokeTwo"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg> -->




  <!-- <svg *ngSwitchCase="'contacts'" class="h-8 w-8 m-auto p-1" width="26" height="28" viewBox="0 0 26 28" fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-outside-1" maskUnits="userSpaceOnUse" x="7" y="12.7812" width="12" height="9" [attr.fill]="fillTwo">
      <rect fill="white" x="7" y="12.7812" width="12" height="9" />
      <path fill-rule="evenodd" clip-rule="evenodd"
        d="M16.988 19.0938C16.9959 18.9906 17 18.8864 17 18.7812C17 16.5721 15.2091 14.7812 13 14.7812C10.7909 14.7812 9 16.5721 9 18.7812C9 18.8864 9.00406 18.9906 9.01203 19.0938H16.988Z" />
    </mask>
    <path
      d="M16.988 19.0938V21.0938C18.0328 21.0938 18.9015 20.2895 18.982 19.2478L16.988 19.0938ZM9.01203 19.0938L7.01797 19.2478C7.09847 20.2895 7.96722 21.0938 9.01203 21.0938V19.0938ZM15 18.7812C15 18.8349 14.9979 18.8878 14.9939 18.9397L18.982 19.2478C18.994 19.0935 19 18.9379 19 18.7812H15ZM13 16.7812C14.1046 16.7812 15 17.6767 15 18.7812H19C19 15.4675 16.3137 12.7812 13 12.7812V16.7812ZM11 18.7812C11 17.6767 11.8954 16.7812 13 16.7812V12.7812C9.68629 12.7812 7 15.4675 7 18.7812H11ZM11.0061 18.9397C11.0021 18.8878 11 18.8349 11 18.7812H7C7 18.9379 7.00605 19.0935 7.01797 19.2478L11.0061 18.9397ZM9.01203 21.0938H16.988V17.0938H9.01203V21.0938Z"
      [attr.fill]="strokeTwo" mask="url(#path-1-outside-1)" />
    <circle cx="13" cy="9.9375" r="2.9375" [attr.stroke]="strokeTwo" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round"  [attr.fill]="fill" />
    <rect x="1" y="1" width="24" height="26" [attr.stroke]="strokeTwo" stroke-width="2" stroke-linecap="round"
      stroke-linejoin="round" />
  </svg> -->




<!-- <svg *ngSwitchCase="'requests'" class="h-8 w-8 m-auto p-1" width="26" height="25" viewBox="0 0 26 25" [attr.fill]="fill"
  xmlns="http://www.w3.org/2000/svg">
  <path
    d="M5.33331 20.3333L6.61331 17.1333V10.8333C6.61331 7.24349 9.52346 4.33334 13.1133 4.33334H13.5533C17.1432 4.33334 20.0533 7.2435 20.0533 10.8333V17.1333L21.3333 20.3333H5.33331Z"
    [attr.stroke]="strokeTwo" stroke-width="2" />
  <path d="M1.33331 10.3333C1.33331 8.55556 2.26665 4.2 5.99998 1" [attr.stroke]="stroke" stroke-width="2" />
  <path d="M24.6666 10.3333C24.6666 8.55556 23.7333 4.2 20 1" [attr.stroke]="stroke" stroke-width="2" />
  <mask id="path-4-inside-1" fill="white">
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M10.6666 22.3333C10.6666 23.8061 11.8605 25 13.3333 25C14.8061 25 16 23.8061 16 22.3333H10.6666Z" />
  </mask>
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M10.6666 22.3333C10.6666 23.8061 11.8605 25 13.3333 25C14.8061 25 16 23.8061 16 22.3333H10.6666Z"
    [attr.fill]="strokeTwo" />
  <path
    d="M10.6666 22.3333V20.3333H8.66663V22.3333H10.6666ZM16 22.3333H18V20.3333H16V22.3333ZM13.3333 23C12.9651 23 12.6666 22.7015 12.6666 22.3333H8.66663C8.66663 24.9107 10.756 27 13.3333 27V23ZM14 22.3333C14 22.7015 13.7015 23 13.3333 23V27C15.9106 27 18 24.9107 18 22.3333H14ZM16 20.3333H10.6666V24.3333H16V20.3333Z"
    [attr.fill]="strokeTwo" mask="url(#path-4-inside-1)" />
</svg> -->



<!-- Config -->
<svg *ngSwitchCase="'config'" [attr.stroke]="strokeTwo" [attr.fill]="fill" version="1.1" id="Layer_1" width="28" height="28" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
  y="0px" viewBox="0 0 100.25 100.25" style="enable-background:new 0 0 100.25 100.25;" xml:space="preserve">
  <g>
    <path [attr.fill]="fill" d="M50,30.5c-10.201,0-18.5,8.299-18.5,18.5S39.799,67.5,50,67.5S68.5,59.201,68.5,49S60.201,30.5,50,30.5z M50,64.5
		c-8.547,0-15.5-6.953-15.5-15.5S41.453,33.5,50,33.5S65.5,40.453,65.5,49S58.547,64.5,50,64.5z" />
    <path [attr.fill]="fill" d="M95.225,41.501L83.257,39.69c-0.658-2.218-1.547-4.372-2.651-6.425l7.176-9.733c0.44-0.597,0.378-1.426-0.146-1.951
		l-9.216-9.215c-0.525-0.524-1.354-0.587-1.951-0.147l-9.702,7.152c-2.062-1.12-4.23-2.022-6.466-2.691L58.5,4.776
		C58.389,4.042,57.759,3.5,57.017,3.5H43.985c-0.742,0-1.372,0.542-1.483,1.276L40.701,16.68c-2.236,0.669-4.404,1.572-6.466,2.691
		l-9.702-7.152c-0.597-0.44-1.426-0.378-1.951,0.147l-9.215,9.215c-0.524,0.524-0.587,1.354-0.147,1.951l7.176,9.733
		c-1.104,2.053-1.993,4.207-2.651,6.425L5.777,41.501c-0.734,0.111-1.276,0.741-1.276,1.483v13.032c0,0.742,0.542,1.372,1.275,1.483
		l12.027,1.82c0.665,2.194,1.552,4.319,2.647,6.341l-7.231,9.808c-0.44,0.597-0.377,1.426,0.147,1.951l9.215,9.215
		c0.524,0.525,1.354,0.587,1.951,0.147l9.84-7.254c2.012,1.08,4.124,1.954,6.3,2.607l1.829,12.09
		c0.111,0.734,0.741,1.276,1.483,1.276h13.032c0.742,0,1.372-0.542,1.483-1.276l1.829-12.09c2.176-0.653,4.288-1.527,6.3-2.607
		l9.84,7.254c0.597,0.44,1.426,0.377,1.951-0.147l9.216-9.215c0.524-0.524,0.587-1.354,0.146-1.951L80.55,65.66
		c1.096-2.022,1.983-4.147,2.647-6.341l12.027-1.82c0.733-0.111,1.275-0.741,1.275-1.483V42.984
		C96.5,42.243,95.958,41.612,95.225,41.501z M93.5,54.726l-11.703,1.771c-0.588,0.089-1.068,0.517-1.224,1.09
		c-0.704,2.595-1.748,5.095-3.103,7.432c-0.3,0.517-0.265,1.162,0.09,1.643l7.04,9.549l-7.391,7.391l-9.578-7.061
		c-0.48-0.353-1.122-0.39-1.637-0.093c-2.331,1.339-4.818,2.369-7.395,3.06c-0.575,0.155-1.005,0.635-1.094,1.225l-1.78,11.769
		H45.273l-1.78-11.769c-0.089-0.589-0.519-1.07-1.094-1.225c-2.577-0.691-5.064-1.721-7.395-3.06
		c-0.515-0.296-1.158-0.259-1.637,0.093l-9.578,7.061l-7.391-7.391l7.04-9.549c0.354-0.481,0.39-1.126,0.09-1.643
		c-1.355-2.336-2.399-4.837-3.103-7.432c-0.156-0.574-0.636-1.001-1.224-1.09L7.498,54.726V44.274l11.65-1.762
		c0.591-0.089,1.073-0.521,1.226-1.099c0.693-2.616,1.735-5.144,3.099-7.514c0.297-0.516,0.26-1.159-0.093-1.638l-6.982-9.471
		l7.391-7.391l9.443,6.961c0.481,0.354,1.126,0.39,1.644,0.089c2.375-1.38,4.916-2.437,7.55-3.142
		c0.576-0.154,1.006-0.635,1.095-1.225l1.752-11.583h10.452l1.752,11.583c0.089,0.59,0.519,1.071,1.095,1.225
		c2.634,0.705,5.174,1.762,7.55,3.142c0.517,0.302,1.162,0.265,1.644-0.089l9.443-6.961L84.6,22.79l-6.982,9.471
		c-0.353,0.479-0.39,1.122-0.093,1.638c1.363,2.37,2.406,4.898,3.099,7.514c0.153,0.578,0.635,1.009,1.226,1.099l11.65,1.762
		L93.5,54.726L93.5,54.726z" />
  </g>
</svg>
<!-- Open Menu -->
<svg *ngSwitchCase="'openMenu'" width="4" height="25" viewBox="0 0 6 25" [attr.fill]="fill" xmlns="http://www.w3.org/2000/svg">
  <circle cx="3" cy="3" r="3" [attr.fill]="fill" />
  <circle cx="3" cy="13" r="3" [attr.fill]="fill" />
  <circle cx="3" cy="22" r="3" [attr.fill]="fill" />
</svg>

<!-- Error -->
  <span class="text-red-500 text-center" *ngSwitchDefault>{{ icon }}</span>
</ng-container>
