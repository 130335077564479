import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigation-icon',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent {
  stroke = '#718096';
  fill = 'A2A2A2';
  strokeTwo = '#718096';
  fillTwo = 'none';
  fillContact = 'none';
  fillContactTwo = 'black';

  @Input() set isActive(val: boolean) {
    // this.stroke = val ? '#ffffff' : '#718096';
    this.fill = val ? '#3d3d3d' : '#A2A2A2';
    // this.strokeTwo = val ? '#3d3d3d' : '#718096';
    // this.fillTwo = val ? '#989898' : 'none';
    // this.fillContact = val ? '#3d3d3d' :'none';
    // this.fillContactTwo=val?'white':'black';
  }

  @Input() icon: string;
  @Input() requestsCount: number;
}
