import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ToastComponent} from './toast/toast.component';
import {AccordionComponent} from './accordion/accordion.component';
import {ReactiveFormsModule} from '@angular/forms';
import {BookingComponent} from './booking/booking.component';
import {AngularMyDatePickerModule} from 'angular-mydatepicker';
import {QrShareComponent} from './qr-share/qr-share.component';
import {QrCodeModule} from 'ng-qrcode';
import {StarsXlComponent} from './stars-xl/stars-xl.component';
import {SliderComponent} from './slider/slider.component';
import {SpinnerModule} from './spinner/spinner.module';

@NgModule({
  declarations: [
    ToastComponent,
    AccordionComponent,
    BookingComponent,
    QrShareComponent,
    StarsXlComponent,
    SliderComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AngularMyDatePickerModule,
    QrCodeModule,
    SpinnerModule,
  ],
  exports: [
    ToastComponent,
    AccordionComponent,
    BookingComponent,
    QrShareComponent,
    StarsXlComponent,
    SliderComponent,
  ],
})
export class SharedModule {}
