import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-redirect-cuny',
  template: ''
  
})
export class RegisterRedirectCunyComponent {
  constructor(private router: Router) { }

  ngOnInit(): void {
   
      this.router.navigate(['/register'], { queryParams: { partner: 'cuny' } });
    
  }
}
