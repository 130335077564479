<ng-container *transloco="let t">
  <div class="router-outlet">
    <router-outlet></router-outlet>
  </div>
  <div class="content-navbar" *ngIf="showNavBar">
    <div
      class="mainNavBar flex flex-row justify-around items-center px-4 py-2 shadow-md border-t border-gray-200 fixed bottom-0 w-screen z-10 font-light">
      <!-- Home -->
      <div
        class="py-1 cursor-pointer focus:outline-none"
        routerLink="{{ t('routing.perfil') }}"
        routerLinkActive="/perfil"
        [routerLinkActiveOptions]="{exact: true}"
        #home="routerLinkActive"
        (click)="menuBottom = false">
        <app-navigation-icon [isActive]="home.isActive" icon="home"></app-navigation-icon>
      </div>
      <!-- Search -->
      <div
        class="py-1 cursor-pointer focus:outline-none"
        routerLink="{{ t('routing.mainSearch') }}"
        routerLinkActive
        #search="routerLinkActive"
        (click)="menuBottom = false">
        <app-navigation-icon [isActive]="search.isActive" icon="search"></app-navigation-icon>
      </div>
      <!-- Contacts -->
      <div
        class="py-1 cursor-pointer focus:outline-none"
        routerLink="{{ t('routing.contactos') }}"
        routerLinkActive
        #contacts="routerLinkActive"
        (click)="menuBottom = false">
        <app-navigation-icon [isActive]="contacts.isActive" icon="contacts"></app-navigation-icon>
      </div>
      <!-- Calendar -->
      <div
        class="py-1 cursor-pointer focus:outline-none"
        routerLink="{{ t('routing.calendar') }}"
        routerLinkActive
        #calendar="routerLinkActive"
        (click)="menuBottom = false">
        <app-navigation-icon [isActive]="calendar.isActive" icon="calendar"></app-navigation-icon>
      </div>
      <!-- Stats -->
      <div
        class="py-1 cursor-pointer focus:outline-none"
        routerLink="{{ t('routing.estadisticas') }}"
        routerLinkActive
        #stats="routerLinkActive"
        (click)="menuBottom = false">
        <app-navigation-icon [isActive]="stats.isActive" icon="stats"></app-navigation-icon>
      </div>
      <!-- Requests -->
      <div
        class="py-1 cursor-pointer focus:outline-none"
        routerLink="{{ t('routing.solicitudes') }}"
        routerLinkActive
        #reqs="routerLinkActive"
        (click)="menuBottom = false">
        <app-navigation-icon [isActive]="reqs.isActive" [requestsCount]="notificationsCount | async" icon="requests">
        </app-navigation-icon>
      </div>
      <!-- Config -->
      <!-- OpenMenu -->
      <div class="py-1 cursor-pointer focus:outline-none" (click)="openMenu()">
        <app-navigation-icon [isActive]="menuBottom" icon="openMenu"></app-navigation-icon>
      </div>
      <!-- === MODAL NOTIFICACIONES === -->
      <div
        *ngIf="showUpdatesModal"
        (click)="applyUpdates()"
        class="centered-block flex flex-row mx-auto w-5/6 fixed z-30 px-1 py-2 shadow-md bg-white rounded-md top-0 mt-8">
        <p class="w-11/12 px-2 text-sm">
          {{ t('avisos.actualizacion') }}
        </p>
        <button (click)="dismissNotification()" class="font-bold text-2xl block w-1/12"> &times; </button>
      </div>
      <!-- === VENTANA DE SERVICIOS AND SHORCUT === -->
      <div class="shortCut" *ngIf="menuBottom">
        <div class="col-button">
          <button class="button-shortCut servicesList" (click)="goToService()">
            <img class="service-icon" src="../assets/shortcut/add-services-icon.png" />
          </button>
          <h2 class="name-shortCut" (click)="goToService()">{{ t('shortCut.services') }}</h2>
        </div>
        <div class="col-button">
          <button class="button-shortCut reserves" (click)="goToReserves()">
            <img class="service-icon" src="../assets/shortcut/reserves.png" />
          </button>
          <h2 class="name-shortCut" (click)="goToAdd()">{{ t('shortCut.reserves') }}</h2>
        </div>

        <!-- FALTA HISTORIAL -->
        <div class="col-button">
          <button class="button-shortCut historial" (click)="goToHistorial()">
            <img class="service-icon" src="../assets/shortcut/historial.png" />
          </button>
          <h2 class="name-shortCut" (click)="goToHistorial()">{{ t('shortCut.historial') }}</h2>
        </div>

        <!--  <div class="col-button">
        <button class="button-shortCut servicesAdd " (click)="goToAdd()">
          <img class="add-icon" src="../assets/service/services-add-icon.png">
        </button>
        <h2 class="name-shortCut" (click)="goToAdd()">{{ts?.shortCut.add}}</h2>
      </div> -->
      </div>
    </div>
  </div>
  <app-toast *ngIf="msj" [msj]="msj"></app-toast>
</ng-container>
