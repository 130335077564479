import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Store, StoreModule } from '@ngrx/store';
import { metaReducers, reducers } from './store.state';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { environment } from '../../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AppSettingsService } from '@services/app-settings.service';
import { appInitializer } from './app.initializer';
import { LocalStorageService } from '@services/local-storage.service';
import { AuthEffects } from '../modules/auth/store/auth.effects';
import { ClientEffects } from '@client-module/store/client.effects';
import { ProfileEffects } from '../modules/profile/store/profile.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    EffectsModule.forRoot([AuthEffects, ClientEffects, ProfileEffects]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'Januus',
          maxAge: 25,
          logOnly: environment.production,
        }),
  ],
  providers: [
    AppSettingsService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [LocalStorageService, AppSettingsService, Store],
    },
  ],
})
export class AppStoreModule {}
