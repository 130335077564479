import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-redirect-gerard',
  template: ''
  
})
export class RegisterRedirectGerardComponent {
  constructor(private router: Router) { }

  ngOnInit(): void {

    this.router.navigate(['/register'], { queryParams: { partner: 'gerard' } });

  }
}
