import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ServicioModel} from '../models/servicio.model';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServiciosService {
  constructor(private http: HttpClient) {}

  agregarServicio(servicio: ServicioModel, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation createMbservicio($createMbservicioInput:CreateMbservicioInput!){
  createMbservicio(createMbservicioInput:$createMbservicioInput){
    success
    message
  }
}`;

    const variables = {
      createMbservicioInput: {
        ...servicio,
      },
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  leerServicios(id: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + 'token')
      .set('x-api-key', environment.apiKey);

    const query = `query mbservicioPorPerfil($id:ID!){
  mbservicioPorPerfil(id:$id){
    _id
    servicio
    descripcion
    divisa
    duracion
    calificacion
    imagen
    imagenPortada
    permitirConfirmarReserva
    listaDisponibilidad{
      dia
      horaInicio
      horaFin
    }
    listaEtiqueta
    precio
    unidadTiempo
    mbPerfil{
    _id
    nombre
    correo
    telefono
    usuario
    }



  }

}`;

    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  borrarServicio(id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `mutation removeMbservicio($id:ID!){
  removeMbservicio(id:$id){
    success
    message
  }
}`;

    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }

  leerServicio(id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);

    const query = `query mbservicio($id:ID!){
  mbservicio(id:$id){
    success
    message
    mbServicio{
      _id
    servicio
    descripcion
    divisa
    duracion
    cupo
    imagen
    imagenPortada
    listaDisponibilidad{
      dia
      horaInicio
      horaFin
    }
    listaEtiqueta
    precio
    unidadTiempo
    permitirConfirmarReserva
    habilitarCupo

    }
  }
}`;

    const variables = {
      id: id,
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
  actualizarServicio(servicio: ServicioModel, id: string, token: string): Observable<any> {
    var headers = new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('x-api-key', environment.apiKey);
    servicio.id = id;
    const query = `mutation updateMbservicio($updateMbservicioInput:UpdateMbservicioInput!){
  updateMbservicio(updateMbservicioInput:$updateMbservicioInput){
    success
    message
  }
}`;

    const variables = {
      updateMbservicioInput: {
        ...servicio,
      },
    };

    return this.http.post(environment.urlGraphql, {query, variables}, {headers});
  }
}
