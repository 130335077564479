import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@helpers/shared.helpers';
import { Mbcliente } from '@models/auth.model';
import { Perfil } from '@models/perfil.model';
import { ReservaResponse } from '@models/reserva.model';

const fileName = 'client';
export const setLoggedClient = createAction(
  buildStringNameForAction(fileName, 'Set logged client'),
  props<{ client: Mbcliente; accountIsActive?: boolean }>(),
);
export const setShowInitialSteps = createAction(
  buildStringNameForAction(fileName, 'Set show initial steps'),
  props<{ showInitialSteps: boolean }>(),
);
export const setActiveProfile = createAction(
  buildStringNameForAction(fileName, 'Set active profile'),
  props<{ activeProfile: Perfil }>(),
);
export const setProfileList = createAction(
  buildStringNameForAction(fileName, 'Set profile list'),
  props<{ profileList: Perfil[] }>(),
);
export const setClientSubscription = createAction(
  buildStringNameForAction(fileName, 'Set client subscription'),
  props<{ mbCliente: Mbcliente }>(),
);
export const setClient2faKey = createAction(
  buildStringNameForAction(fileName, 'Set client 2fa key'),
  props<{ llaveFactorAutenticacion: string | null }>(),
);
export const setContactsRequest = createAction(
  buildStringNameForAction(fileName, 'Set contacts request'),
  props<{ activeProfileContactRequest: Perfil[] }>(),
);
export const setContactRequest = createAction(
  buildStringNameForAction(fileName, 'Set contact request'),
  props<{ activeProfileContactRequest: Perfil }>(),
);

export const setProfileReservations = createAction(
  buildStringNameForAction(fileName, 'Set profile reservations'),
  props<{ activeProfileReservations: ReservaResponse[] }>(),
);

export const setProfileReservation = createAction(
  buildStringNameForAction(fileName, 'Set profile reservation'),
  props<{ activeProfileReservation: ReservaResponse }>(),
);

export const setSelectedPlan = createAction(
  buildStringNameForAction(fileName, 'Set selectedPlan'),
  props<{ selectedPlan: boolean }>(),
);
export const payVerifyLoad = createAction(
  buildStringNameForAction(fileName, 'Pay verify Load'),
  props<{ cardInfo: object, metadata: object }>(),
);
export const payVerifySuccess = createAction(
  buildStringNameForAction(fileName, 'Pay verify Success'),
);
export const payVerifyError = createAction(
  buildStringNameForAction(fileName, 'Pay verify Error'),
);
