import { selectClientsState } from '../../../core/store.state';
import { createSelector } from '@ngrx/store';
import { ClientState } from '@client-module/models/client-state.model';
import { Mbcliente } from '@models/auth.model';
import { Perfil } from '@models/perfil.model';
import { ReservaResponse } from '@models/reserva.model';
import { orderBy } from 'lodash-es';
import { Price } from '@settings-module/models/settings-state.model';
import { AccountTypes } from '@helpers/core.helpers';
import {
  selectVerifyPaymentBothPrice,
  selectVerifyPaymentBusinessPrice,
  selectVerifyPaymentIndividualPrice,
} from '@settings-module/store/settings.selectors';

export const selectClientState = createSelector(selectClientsState, (state: ClientState) => state);
export const selectClient = createSelector(
  selectClientState,
  (state: ClientState): Mbcliente => state?.client,
);

export const selectShowInitialSteps = createSelector(
  selectClientState,
  (state: ClientState) => state?.showInitialSteps,
);

export const selectClientAccountType = createSelector(
  selectClient,
  (state: Mbcliente) => state?.mbTipoCuenta?._id,
);
export const selectClientCode = createSelector(
  selectClient,
  (state: Mbcliente): string => state?.codigo,
);
export const selectIsGoogleAccount = createSelector(
  selectClient,
  (state: Mbcliente): boolean => !!state?.idGoogle,
);
export const selectClientEmail = createSelector(
  selectClient,
  (state: Mbcliente): string => state?.correo,
);
export const selectClientSubscription = createSelector(
  selectClient,
  (state: Mbcliente): string => state?.tipoSuscripcion,
);
export const selectClientPoints = createSelector(
  selectClient,
  (state: Mbcliente): number => state?.puntos,
);

export const selectActiveProfile = createSelector(
  selectClientState,
  (state: ClientState): Perfil => state?.activeProfile,
);
export const selectActiveProfileId = createSelector(
  selectClientState,
  (state: ClientState): string => state?.activeProfile?._id,
);
export const selectProfileList = createSelector(selectClientState, (state: ClientState): Perfil[] =>
  orderBy(state?.profileList, ['esActivo'], ['desc']),
);
export const selectProfileListLength = createSelector(
  selectClientState,
  (state: ClientState): number => state?.profileList?.length || 0,
);
export const selectActiveProfileContactRequest = createSelector(
  selectClientState,
  (state: ClientState): Perfil[] => state?.activeProfileContactRequest,
);

export const selectTheme = createSelector(selectActiveProfile, (state: Perfil) => state?.tema);
export const selectActiveProfileReservations = createSelector(
  selectClientState,
  (state: ClientState): ReservaResponse[] => state?.activeProfileReservations,
);

export const selectNotificationsCount = createSelector(
  selectActiveProfileContactRequest,
  selectActiveProfileReservations,
  (activeProfileContactRequest: Perfil[], activeProfileReservations: ReservaResponse[]) =>
    (activeProfileContactRequest?.length || 0) + (activeProfileReservations?.length || 0),
);
export const selectIsLoadingClients = createSelector(
  selectClientState,
  (state: ClientState): boolean => state?.isLoadingClients,
);
export const selectClientsResults = createSelector(
  selectClientState,
  (state: ClientState): Mbcliente[] => state?.clientsResults,
);
export const selectClientToValidate = createSelector(
  selectClientState,
  (state: ClientState): Mbcliente => state?.selectedClientToValidate,
);
export const selectClientToValidateId = createSelector(
  selectClientToValidate,
  (state: Mbcliente): string => state?._id,
);
export const selectClientToValidateIsLogged = createSelector(
  selectClientState,
  (state: ClientState): boolean => state?.selectedClientToValidateIsLogged,
);
export const selectShowModalForPassword = createSelector(
  selectClientState,
  (state: ClientState): boolean => state?.showModalForPassword,
);
export const selectShowModalForCreateAccount = createSelector(
  selectClientState,
  (state: ClientState): boolean => state?.showModalForCreateAccount,
);
export const selectPriceToPay = createSelector(
  selectClientAccountType,
  selectClientToValidate,
  selectVerifyPaymentIndividualPrice,
  selectVerifyPaymentBusinessPrice,
  selectVerifyPaymentBothPrice,
  (accountType, clientToValidate, individualPrice, businessPrice, bothPrice): Price => {
    return accountType === AccountTypes.INDIVIDUAL
      ? individualPrice
      : clientToValidate.esValidoAuthenticate
      ? businessPrice
      : bothPrice;
  },
);
